import React from 'react';

const Twitter: React.FC = () => (
  <svg role="img" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M512 97.699c-19.002 8.4-39.403 14-60.803 16.5 21.803-13 38.403-33.5 46.203-58.5-20.4 12.1-42.801 21-66.403 25.8-19.402-20.5-47.203-33.5-77.203-33.5-58.002 0-104.803 47.1-104.803 104.8 0 8.3.703 16.3 2.102 24-87.102-4.3-164.803-46.1-216.803-109.8-9 15.5-14.102 33.5-14.102 52.8 0 36.3 18.402 68.8 46.203 87.2-16.902-.3-33.102-5.1-47.203-12.8v1.2c0 50.8 36.102 93.1 83.403 103-8.7 2.4-18.102 3.6-27.803 3.6-6.7 0-13.102-.5-19.402-1.6 13.402 41.8 51.803 72.3 97.403 73.1-35.702 28.3-80.803 45.1-129.803 45.1-8.4 0-16.702-.3-25-1 46.002 29.7 101.403 47.1 161.003 47.1 193.002 0 298.803-160.1 298.803-298.8 0-4.6-.102-9.1-.102-13.6 20.5-14.8 38.102-33.1 52.203-54.2z"
    />
  </svg>
);

export default Twitter;
